import UIkit from 'uikit';

export default function initAccordion() {
  var accordionButtons = document.querySelectorAll(".accordionToggle");

  accordionButtons.forEach((el) => {
    el.addEventListener("click", function(e) {
      var parentID = el.parentElement.parentElement.id
      var accordionContent = document.querySelectorAll("#" + parentID + " .uk-accordion-content");
      var accordionTitle = document.querySelectorAll("#" + parentID + " .uk-accordion-expand");

      if($(this).hasClass( "open" )){ //collapse all

        accordionTitle.forEach((title, index) => {
          //click if content is visible
          if (!accordionContent[index].hasAttribute("hidden")) {
            title.click()
          }
        })

        $(this).text('Expand all');
        $(this).removeClass('open');
      } else { // expand all

        accordionTitle.forEach((title, index) => {
          //click if content is hidden
          if (accordionContent[index].hasAttribute("hidden")) {
            title.click()
          }
        })
        
        $(this).text('Collapse all')
        $(this).addClass('open')
      }
    });    
  });

}
