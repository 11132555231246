import UIkit from 'uikit';

export default function initGallery() {
    var el = document.querySelectorAll(".gallery-item");

    for(var i =0; i < el.length; i++) {
        el[i].addEventListener("click", function(e) {
            let index = e.target.dataset['index']

            UIkit.modal('#image-slider-modal').show();

            UIkit.slider("#image-slider").show();
            UIkit.slider("#image-slider", {
                index: index - 1
            });
        });
    }

    var gallerySlider = document.querySelectorAll("#image-slider")
    // assume that user is not clicking on a next/prev button
    // if not pressing a button, prevent the slider from default action (slide)
    
    for(var i =0; i < gallerySlider.length; i++) {
        gallerySlider[i].addEventListener("click", function(e) {
            let notButton = true
            
            for(var i =0; i < e.path.length; i++) {
                if (e.path[i].classList) {
                    if (e.path[i].classList.contains('slider-nav-arrows') || e.path[i].classList.contains('uk-modal-close')) {
                        notButton = false
                    }                    
                } else if (e.target.classList.contains('slider-nav-arrows')) {
                    notButton = false
                }
            }

            if (notButton) {
                e.stopImmediatePropagation();
            }
        });
    }
}
