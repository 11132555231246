//
// Main Navigation.
//
export default function mainNavigation() {

  var parentItem = $('.parent_menu_item a')
  var childItem = $('.child_menu_item a')

  // parentItem.on('focus', parentItemChange)
  parentItem.on('click', parentItemChange)
  parentItem.on('keypress', parentItemChange)

  function parentItemChange(e){
    var parentID = $(this).attr('id')
    parentItem.removeClass('active')
    $(this).addClass('active')


    $('.child_menu ul').each(function( index ) {
      var value = $(this).attr('value');

      if(value == parentID){
        $(this).show()
        $(this).trigger('focus')
        $(this).children(':first-child').children().addClass("active")
      } else {
        $(this).hide()
        $(this).children().children().removeClass("active")
      }

      if($(this).children().children().hasClass("active")){
        var subchildid = $(this).children(':first-child').children().attr('id')


        $('.subchild_menu > div').each(function( index ) {
          var value = $(this).attr('value');

          if(value == subchildid){
            $(this).show()
          } else {
            $(this).hide()
          }

        });

      }
    });
  };

  // childItem.on('focus', childItemChange)
  childItem.on('click', childItemChange)
  childItem.on('keypress', childItemChange)

  function childItemChange(e){

    var childID = $(this).attr('id')
    childItem.removeClass('active')
    e.preventDefault();
    $(this).addClass('active')

    $('.subchild_menu > div').each(function( index ) {
      var value = $(this).attr('value');

      if(value == childID){
        $(this).show()
        $(this).trigger('focus')
      } else {
        $(this).hide()
      }

    });

  };

  subChildEvent()

  function subChildEvent() {
    var childID = $('.child_menu_item a.active').attr('id');

    $('.subchild_menu > div').each(function( index ) {
      var value = $(this).attr('value');

      if(value == childID){
        $(this).show()
      } else {
        $(this).hide()
      }

    });
  }


  $('.menu-overlay').on('click', menuEvent)
  $('.menuOpen').on('click', menuEvent).on('keypress', menuEvent)

  function menuEvent(event) {
    event.preventDefault();
    $('nav.main_navigation').fadeToggle();
    $('.menuOpen .close').toggle();
    $('.menuOpen .menu').toggle();
    $('.menu-overlay').fadeToggle();
  }





//   // NEW

//   const mainMenuItems = document.querySelectorAll('.parent_menu_item')


//   mainMenuItems.forEach((item, index) => {
//     item.addEventListener('focusin', function (event) {
//       item.querySelector('.child_menu').classList.remove('hidden')

//       mainMenuItems.forEach((otherItem, subindex) => {
//         if (index !== subindex && !otherItem.querySelector('.child_menu')
//         .classList.contains('hidden')) {
//           otherItem.querySelector('child_menu').classList.add('hidden')
//         }
//       })
//     })


//     item.addEventListener('focusout', function (event) {
//       const targetClassList = event.relatedTarget.classList

//       if (!targetClassList.contains('menu-link') && 
//       !targetClassList.contains("parent_menu_item")) {
//         item.querySelector('.child-menu').classList.add('hidden')
//       }
//     })


//   })

}
