// import $ from 'cash-dom';
import clip from 'text-clipper';

export default function truncate() {
  var allTruncate = $('.js-truncate');

  allTruncate.each((index) => {
    var truncateShow = allTruncate.eq(index).find('.js-truncate-show');
    var textString = allTruncate.eq(index).find('.js-truncate-text').html();
    var readMoreButton = '<a href="#" class="js-truncate-show">Show more <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg></a>';
    var viewLessButton = '<a href="#" class="js-truncate-hide">Show less <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line></svg></a>';
    var clipped = clip(textString, 400, { html: true, maxLines: 8, indicator: '...'});

    var final = $(clipped);

    if (textString !== clipped) {
        allTruncate.eq(index).find('.js-truncate-container').append(final).append(readMoreButton);
        allTruncate.eq(index).addClass('is-truncated').find('.js-truncate-text').addClass('uk-hidden')
        allTruncate.eq(index).find('.js-truncate-text').append(viewLessButton);
    }

    $('.js-truncate-show').on('click', function() {
        if($(this).parents('.js-truncate')[0].classList.contains('is-truncated')){
            $(this).parents('.js-truncate')[0].classList.remove('is-truncated')
            $(this).parents('.js-truncate').eq(0).find('.js-truncate-text').removeClass('uk-hidden');
            $(this).parents('.js-truncate').eq(0).find('.js-truncate-container').addClass('uk-hidden');
            $(this).addClass('uk-hidden')
        }

        return false;
    });

    $('.js-truncate-hide').on('click', function() {
        if(!$(this).parents('.js-truncate')[0].classList.contains('is-truncated')){
            $(this).parents('.js-truncate')[0].classList.add('is-truncated');
            $(this).parents('.js-truncate').eq(0).find('.js-truncate-text').addClass('uk-hidden');
            $(this).parents('.js-truncate').eq(0).find('.js-truncate-container').removeClass('uk-hidden');
            $(this).removeClass('uk-hidden');
            $(this).parents('.js-truncate').eq(0).find('.js-truncate-show').removeClass('uk-hidden');
        }

        return false;
    });
  })
}
