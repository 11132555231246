// currently we still need jquery for the career list and property taxes pages
// templates/jobs/career_list.html
// templates/propertytaxes/calculator.html
// templates_legacy/county_connect/partials/track_it_javascript.html
import jQuery from 'jquery';

import 'objectFitPolyfill/src/objectFitPolyfill.basic'; // polyfill for css object fit (needed on edge/ie)

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

import initGreeting from './base/hello';
import initCSRFToken from './base/csrf';
import initGallery from './base/gallery';
import truncate from './base/truncate';
import initAccordion from './base/accordion';
import mainNavigation from './base/main_navigation';

import List from 'list.js';

((function App(window, $) { // eslint-disable-line no-unused-vars
  const LIFT = {
    /**
     * Base view.
     *
     * Called on every page/view. Add global components here (eg: navigation
     * modules, etc.).
     */
    common: {

      /**
       * `init` is the action each view executes by default.
       */
      init() {
        /**
         * Lift default modules.
         */
        initGreeting();
        initCSRFToken(); // Needed for Ajax
        initAccordion();
        mainNavigation();

        /**
         * UIkit
         */
        // loads the Icon plugin
        UIkit.use(Icons);

        /*
         * Offcanvas menu button
         */
        const offcanvas = document.getElementById('lf-offcanvas');
        const offcanvasToggle = document.getElementById('lf-offcanvas-toggle');
        const firstNavItem = offcanvas.querySelector('.lf-nav-offcanvas > li > a');

        offcanvas.addEventListener('shown', (event) => {
          if (event.target == offcanvas) {
            offcanvasToggle.setAttribute('aria-expanded', 'true');
            firstNavItem.focus();
          }
        });
        offcanvas.addEventListener('hidden', (event) => {
          if (event.target == offcanvas) {
            offcanvasToggle.setAttribute('aria-expanded', 'false');
            offcanvasToggle.focus();
          }
        });

        /*
         * Tabbing past en of menu should close offcanvas
         */
        const offcanvasLinks = offcanvas.querySelectorAll('a');
        const lastLink = offcanvasLinks[offcanvasLinks.length - 1];
        lastLink.addEventListener('keydown', (event) => {
          if (event.key === 'Tab') {
            UIkit.offcanvas(offcanvas).hide();
            offcanvasToggle.focus();
          }
        });

        /*
         * Toggle aria-expanded state for offcanvas sub-navs
         */
        const offcanvasNavButtons = Array.from(offcanvas.querySelectorAll('.lf-nav-offcanvas button'));

        function handleNavButton(event) {
          // store initial state
          const expanded = this.getAttribute('aria-expanded');

          // set all to false
          offcanvasNavButtons.forEach((button) => {
            button.setAttribute('aria-expanded', 'false');
          });

          // if this one wasn't expanded before, expand it
          if (expanded === 'false') {
            this.setAttribute('aria-expanded', 'true');
          }
        }

        offcanvasNavButtons.forEach((button) => {
          button.addEventListener('click', handleNavButton.bind(button));
        });


        /**
         * Site Header Sticky Nav
         */
        // const subbar = document.getElementById('lf-navbar-subbar');
        // const subbarToggle = document.getElementById('lf-navbar-subbar-toggle');
        // const searchDrop = document.getElementById('search-drop');

        // UIkit.util.on('#lf-sticky-header', 'active', () => {
        //   // hide search drop when sticky state changes
        //   subbarToggle.removeAttribute('hidden');
        //   subbarToggle.setAttribute('aria-hidden', 'false');
        //   subbar.setAttribute('hidden', 'hidden');
        //   subbar.setAttribute('aria-hidden', 'true');
        //   if (searchDrop) {
        //     UIkit.drop(searchDrop).hide();
        //   }
        // });

        // UIkit.util.on('#lf-sticky-header', 'inactive', () => {
        //   subbarToggle.setAttribute('hidden', 'hidden');
        //   subbarToggle.setAttribute('aria-hidden', 'true');
        //   subbar.removeAttribute('hidden');
        //   subbar.setAttribute('aria-hidden', 'false');
        //   if (searchDrop) {
        //     UIkit.drop(searchDrop).hide();
        //   }
        // });

        /**
         * Everbridge notifications modal and alerts.
         * Cookies dismiss individual notifications until session ends (YMMV).
         */

        function setCookie(name, value, minutes) {
          let expires = '';
          if (minutes) {
            const date = new Date();
            date.setTime(date.getTime() + (minutes * 60 * 1000));
            expires = `; expires=${date.toUTCString()}`;
          }
          document.cookie = `${name}=${value || ''}${expires}; path=/`;
        }

        function getCookie(name) {
          const nameEQ = `${name}=`;
          const ca = document.cookie.split(';');
          for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
          }
          return null;
        }

        const modals = $('.lf-everbridge-modal');
        const alerts = $('.lf-everbridge-alert');
        const alertClosers = document.querySelectorAll('.lf-everbridge-alert-close');
        let openModal = false;

        function showNotices() {
          if (modals) {
            $(modals).each(function () {
              const noticeID = $(this).attr('data-notice-id');
              if (!getCookie(`notice_${noticeID}_dismissed`)) {
                UIkit.modal($(this)).show();
                $(this).on('shown', () => {
                  openModal = true;
                  const modalClosers = document.querySelectorAll('.uk-modal-close-outside');
                  modalClosers.forEach((closer) => {
                    closer.addEventListener('keydown', (e) => {
                      if (e.which === 9) {
                        e.preventDefault();
                      }
                      if (e.code === 'Escape' || e.code === 'Enter' || e.code === 'Space') {
                        openModal = false;
                        closer.click();
                      }
                    });
                  });
                  modalClosers[modalClosers.length - 1].focus();
                });
                $(this).on('hide', () => {
                  if (alertClosers) {
                    setTimeout(() => {
                      alertClosers[0].focus();
                    }, 200);
                  }
                });
              }
            });
          }
          if (alerts) {
            $(alerts).each(function () {
              const noticeID = $(this).attr('data-notice-id');
              if (!getCookie(`notice_${noticeID}_banner_dismissed`)) {
                $(this).removeClass('uk-hidden');
              }
            });
            let closerCount = 0;
            if (!openModal) {
              const hiddenAlerts = document.querySelectorAll('.lf-everbridge-alert.uk-hidden');
              if (hiddenAlerts.length) {
                closerCount += hiddenAlerts.length;
              }
              setTimeout(() => {
                if (alertClosers.length) {
                    alertClosers[closerCount].focus();
                }
              }, 200);
            }
            alertClosers.forEach((closer) => {
              closer.addEventListener('keyup', (e) => {
                if (e.code === 'Escape' || e.code === 'Enter' || e.code === 'Space') {
                  closer.click();
                  closerCount += 1;
                  if (closerCount <= alertClosers.length - 1) {
                    alertClosers[closerCount].focus();
                  }
                }
              });
            });
          }
        }

        function dismissNotices(noticeID, banner = false) {
          if (banner == false) {
            setCookie(`notice_${noticeID}_dismissed`, true, 30);
          } else {
            setCookie(`notice_${noticeID}_banner_dismissed`, true, 30);
          }
        }

        showNotices();

        $('.lf-everbridge-alert .lf-everbridge-alert-close').click(function () {
          const noticeID = $(this).attr('data-notice-id');
          dismissNotices(noticeID, true);
        });
        $('.lf-everbridge-modal .uk-modal-close-outside').click(function () {
          const noticeID = $(this).attr('data-notice-id');
          dismissNotices(noticeID);
        });

        // site header search
        const searchButton = $('.lf-search-toggle');
        const searchForm = document.getElementById('search-form');
        const searchInput = document.getElementById('search_header');

        function submitSearchFormIfSearchInputHasValue(clickevent) {
          this.classList.toggle('uk-open');
          if (searchInput.value) {
            searchForm.submit();
            return false;
          }
        }

        searchButton.click(submitSearchFormIfSearchInputHasValue);

        /**
         * Trigger sticky active event if there is a location hash
         * so that our sticky header switched to condensed version
         */
        if (window.location.hash) {
          const header = document.getElementById('lf-sticky-header');
          header.dispatchEvent(new Event('active'));
        }

        /*
         * Open FAQ item in accordion if location hash exists with matching id.
         */
        let hash = window.location.hash;
        if (hash) {
          hash = hash.slice(1); // remove hash symbol
          const accordionTitle = document.getElementById(hash);
          if (accordionTitle) {
            const accordion = accordionTitle.closest('ul');
            const accordionItem = accordionTitle.closest('li');

            /*
             * Adjusting the height so as to not cut off the title with the nav.
             */
            setTimeout(() => {
              accordionItem.scrollIntoView();
              window.scrollBy(0, -60);
            }, 0);

            if (accordion) {
              UIkit.accordion(accordion).toggle(accordionItem);
            }
          }
        }

        /*
         * Open FAQ item if linked to from On This Page, which may or may not
         * be a FAQ item.
         */
        jQuery('.lf-page-anchor-links a').click(function (e) {
          const potentialAccordionItem = jQuery(jQuery(this).attr('href'));
          const accordion = potentialAccordionItem.closest('ul');
          const accordionItem = potentialAccordionItem.closest('li');

          if (accordion && accordionItem) {
            setTimeout(() => {
              accordionItem[0].scrollIntoView();
              window.scrollBy(0, -60);
            }, 0);

            if (!jQuery(accordionItem).hasClass('uk-open')) {
              UIkit.accordion(accordion).toggle(accordionItem, false);
            }

            e.preventDefault();
            return false;
          }
        });

        function toggleDocumentLibrary() {
          if (jQuery('.toggle-document-plugin').length > 0) {
            const toggleIcons = jQuery('.toggle-document-plugin');

            toggleIcons.on('click', function (event) {
              if (jQuery(this).hasClass('active')) {
                jQuery(this).removeClass('active');
                const toggleParentContainer = jQuery(this).next();
                const toggleInnerHeight = jQuery(toggleParentContainer).find('.inner').outerHeight();

                jQuery(toggleParentContainer).outerHeight('0px');
                jQuery(toggleParentContainer).css('visibility', 'hidden');
                jQuery(toggleParentContainer).css('opacity', 0);
                jQuery(this).find('.lf-toggle-document').attr('uk-icon', 'plus');
              } else {
                jQuery(this).addClass('active');
                const toggleParentContainer = jQuery(this).next();
                jQuery(toggleParentContainer).css('height', 'auto'); // Reset to default height
                const toggleInnerHeight = jQuery(toggleParentContainer).find('.inner').outerHeight();

                jQuery(toggleParentContainer).outerHeight(`${toggleInnerHeight}px`); // Set to resized height
                jQuery(toggleParentContainer).css('visibility', 'visible');
                jQuery(toggleParentContainer).css('opacity', 1);
                jQuery(this).find('.lf-toggle-document').attr('uk-icon', 'minus');
              }
            });

            // Fix for issues on resize/orientation change.
            jQuery(window).on('resize orientationchange', (event) => {
              jQuery('.toggle-document-plugin.active').each(function () {
                if (jQuery(this).hasClass('active')) {
                  const toggleParentContainer = jQuery(this).next();
                  jQuery(toggleParentContainer).css('height', 'auto'); // Reset to default height
                  const toggleInnerHeight = jQuery(toggleParentContainer).find('.inner').outerHeight();
                  jQuery(toggleParentContainer).outerHeight(`${toggleInnerHeight}px`); // Set to resized height
                }
              });
            });
          }
        }
        toggleDocumentLibrary();
        initGallery();
        truncate();
      },
    },
    propertyTaxes: {
      init() {
        UIkit.util.on('#tab-switcher', 'shown', () => {
          // this is for the property taxes calculator
          // when a tab is switched on the page we need to recalculate
          // the charts
          const ev = document.createEvent('Event');
          ev.initEvent('resize', true, true);
          window.dispatchEvent(ev);
        });
      },
    },
    countyConnectDetail: {
      init() {
        // show the county connect modal content on page load
        // if it exists
        const modal = document.getElementById('connect_modal');

        if (modal) {
          UIkit.modal(modal).show();
        }
      },
    },
    careerList: {
      init() {
        const careerList = this.initList();

        const paginationToggle = document.getElementById('career-pagination-toggle');
        if (paginationToggle) { paginationToggle.addEventListener('click', this.handlePaginationToggle.bind(this, paginationToggle, careerList)); }

        careerList.on('updated', () => {
          // scroll to top of list container
          // subtract height of sticky header and some padding
          // wait until next tick to allow pagination to re-initialize when triggering "view by page"
          window.setTimeout(() => {
            const scrollPos = document.getElementById('career-list-container').getBoundingClientRect().top +
              window.scrollY -
              60 -
              20;
            window.scroll({
              top: scrollPos,
              behavior: 'smooth',
            });
          }, 0);
        });
      },
      handlePaginationToggle(paginationToggle, listObj) {
        const paginationContainer = document.getElementById('career-pagination-container');

        if (listObj.pagination) {
          // hide pagination container
          paginationContainer.hidden = true;
          // change button label
          paginationToggle.textContent = 'View by page';
          // clear filters
          listObj.filter();
          // change pagination options
          listObj.pagination = false;
          listObj.page = Infinity;
          // update list.js
          listObj.update();
        } else {
          paginationContainer.hidden = false;
          paginationToggle.textContent = 'View all opportunities';
          listObj.filter();
          listObj.pagination = {
            innerWindow: Infinity,
          };
          listObj.page = 15;
          listObj.update();
        }
      },
      initList() {
        /*
         * Initialize list.js
         */
        const options = {
          valueNames: ['position'],
        };

        const careerList = new List('career-list', options);

        /*
         * Set up listeners
         */
        const positionSelect = document.getElementById('career-position');
        const positionSelectMobile = document.getElementById('career-position-mobile');
        positionSelect.addEventListener('change', this.handleSelectChange.bind(null, careerList, positionSelect));
        positionSelectMobile.addEventListener('change', this.handleSelectChange.bind(null, careerList, positionSelectMobile));
        careerList.on('updated', this.handleListUpdated.bind(this, careerList));

        /*
         * Return List.js object so we can use it later
         */
        return careerList;
      },
      handleSelectChange(listObj, positionSelect) {
        const positionValue = positionSelect.value;

        if (positionValue) {
          listObj.filter((item) => {
            if (item.values().position === positionValue) {
              return true;
            }
            return false;
          });
        } else if (positionValue) {
          listObj.filter((item) => {
            if (item.values().position === positionValue) {
              return true;
            }
            return false;
          });
        } else {
          listObj.filter();
        }
      },
      handleListUpdated(listObj) {
        if (listObj.visibleItems.length) {
          this.hideEmptyMessage();

          listObj.visibleItems.forEach((item, index) => {
            if (index % 2) {
              // even
              item.elm.firstElementChild.classList.remove('uk-card-secondary');
            } else {
              // odd
              item.elm.firstElementChild.classList.add('uk-card-secondary');
            }
          });
        } else {
          this.showEmptyMessage();
        }
      },
      showEmptyMessage() {
        const empty = document.getElementById('career-list-empty');
        empty.hidden = false;
      },
      hideEmptyMessage() {
        const empty = document.getElementById('career-list-empty');
        empty.hidden = true;
      },
    },
  };


  const UTIL = {
    /**
     * Executes the defined view function.
     *
     * @param {Object}   model         - The current view.
     * @param {Function} [action=init] - The function to execute.
     */
    exec(model, action) {
      const modelAction = (action === undefined) ? 'init' : action;

      if (model !== '' && LIFT[model] && typeof LIFT[model][modelAction] === 'function') {
        LIFT[model][modelAction]();
      }
    },

    /**
     * Gets the view and action (if defined) from the main DOM container.
     *
     * @example
     *     <div id="body" data-model="home" data-action="init">
     */
    init() {
      const sitewrap = document.getElementsByTagName('body')[0];
      const model = sitewrap.getAttribute('data-model');
      const action = sitewrap.getAttribute('data-action');

      // Calls LIFT.common.init()
      UTIL.exec('common');
      // Calls LIFT.model.init()
      UTIL.exec(model);

      if (action !== undefined) {
        // Calls LIFT.model.action()
        UTIL.exec(model, action);
      }
    },
  };

  /** Lift-off in T-minus DOMReady. */
  // in case the document is already rendered
  if (document.readyState !== 'loading') UTIL.init();
  // modern browsers
  else if (document.addEventListener) document.addEventListener('DOMContentLoaded', UTIL.init);
  // IE <= 8
  else {
    document.attachEvent('onreadystatechange', () => {
      if (document.readyState === 'complete') UTIL.init();
    });
  }
})(window, jQuery));
